import React from "react";
import _ from "lodash";

import { Link, markdownify, safePrefix } from "../utils";
import { css } from "styled-components/macro";
import { between } from "polished";
import Helmet from "react-helmet";

export default class HeroBlock extends React.Component {
  render() {
    const { siteUrl: originUrl } =
      this.props?.pageContext?.site?.siteMetadata || {};
    const imgUrl = `${originUrl}/${this.props.section.image}`;
    return (
      <>
        <Helmet>
          <meta property="og:image" content={imgUrl} />
        </Helmet>
        <section
          id={_.get(this.props, "section.section_id")}
          className="block hero-block bg-accent outer"
        >
          <div className="inner">
            <div className="grid">
              <div className="cell block-content">
                <H2
                  css={css`
                    && {
                      font-size: ${between("32px", "40px")};
                    }
                  `}
                >
                  {_.get(this.props, "section.title")}
                </H2>
                <div className="block-copy">
                  {markdownify(_.get(this.props, "section.content"))}
                </div>
                {_.get(this.props, "section.actions") && (
                  <p className="block-buttons">
                    {_.map(
                      _.get(this.props, "section.actions"),
                      (action, action_idx) => (
                        <Link
                          key={action_idx}
                          to={safePrefix(_.get(action, "url"))}
                          className="button white large"
                        >
                          {_.get(action, "label")}
                        </Link>
                      )
                    )}
                  </p>
                )}
              </div>
              {_.get(this.props, "section.image") && (
                <div className="cell block-preview">
                  <img
                    css={css`
                      box-shadow: 0 3px 10px 0 rgba(76, 82, 103, 0.15);
                      border-radius: 4px;
                    `}
                    src={safePrefix(_.get(this.props, "section.image"))}
                    alt={_.get(this.props, "pageContext.frontmatter.title")}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

const H2 = ({ className, ...rest }) => (
  <h2 className={`block-title underline ${className}`} {...rest} />
);
