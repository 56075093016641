import React from "react";
import _ from "lodash";
import { css } from "styled-components/macro";

import { htmlToReact, Link, safePrefix } from "../utils";
import Social from "./Social";
import SubscribeForm from "./SubscribeForm";
// import Instagram from './Instagram'
// import { ErrorBoundary } from 'react-error-boundary'

export default class Footer extends React.Component {
  render() {
    return (
      <footer id="colophon" className="site-footer">
        <div className="footer-top outer">
          {/*<ErrorBoundary fallback={<div />}>*/}
          {/*  <Instagram />*/}
          {/*</ErrorBoundary>*/}

          <div className="inner">
            <div className="footer-widgets">
              <div className="widget footer-branding">
                {_.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.logo_img"
                ) ? (
                  <p className="site-logo">
                    <Link to={safePrefix("/")}>
                      <img
                        src={safePrefix(
                          _.get(
                            this.props,
                            "pageContext.site.siteMetadata.footer.logo_img"
                          )
                        )}
                        alt="Logo"
                      />
                    </Link>
                  </p>
                ) : (
                  <p className="site-title">
                    <Link to={safePrefix("/")}>
                      {_.get(
                        this.props,
                        "pageContext.site.siteMetadata.header.title"
                      )}
                    </Link>
                  </p>
                )}
                {_.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.tagline"
                ) && (
                  <p className="site-description">
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.footer.tagline"
                    )}
                  </p>
                )}
                <div>
                    <h5 className="widget-title">Fundacja Dobra przestrzeń</h5>
                    <strong>Adres:</strong>
                    <p>
                      <Link to="/kontakt">
                        ul. Drzymały 18/24 <br />
                        Warszawa, Ursus <br />
                        02-495
                      </Link>
                    </p>
                    <strong>Telefon:</strong>
                    <p>
                      <a href="tel:+48575550548">575-550-548</a>
                      <br />
                      <a href="tel:+48668879214">668-879-214</a>
                    </p>
                    <strong>Adres email:</strong>
                    <p>
                    <a href="mailto:kontakt@dobra-przestrzen.pl">
                      kontakt@dobra-przestrzen.pl </a>
                    </p>
                  <div>
                    <h5 className="widget-title">Centrum psychologiczne</h5>
                    <p>Psychoterapia, joga, warsztaty, szkolenia</p>
                    <strong>Adres:</strong>
                    <p>
                      <Link to="/kontakt">
                        ul. Fasolowa 33 <br />
                        Warszawa, Włochy <br />
                        02-482
                      </Link>
                    </p>
                  </div>
                  <div>
                    <h5 className="widget-title">
                      Niepubliczna poradnia psychologiczno-pedagogiczna
                    </h5>
                    <p>
                      Dla dzieci i młodzieży: <br /> diagnostyka, psychoterapia,
                      warsztaty
                    </p>
                    <strong>Adres:</strong>
                    <p>
                      <Link to="/kontakt">
                        Rakietników 10 <br />
                        Warszawa, Ursus <br />
                        02-495
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              {((_.get(this.props, "pageContext.menus.secondary") &&
                _.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.has_nav"
                )) ||
                _.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.has_social"
                )) && (
                <nav className="widget footer-navigation">
                  <div className="footer-nav-inside">
                    {_.get(this.props, "pageContext.menus.secondary") &&
                      _.get(
                        this.props,
                        "pageContext.site.siteMetadata.footer.has_nav"
                      ) && (
                        <div className="secondary-nav">
                          <h2 className="widget-title">
                            {_.get(
                              this.props,
                              "pageContext.site.siteMetadata.footer.nav_title"
                            )}
                          </h2>
                          <ul className="secondary-menu">
                            {_.map(
                              _.get(this.props, "pageContext.menus.secondary"),
                              (item, item_idx) => (
                                <li key={item_idx}>
                                  <Link to={safePrefix(_.get(item, "url"))}>
                                    {_.get(item, "title")}
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.footer.has_social"
                    ) && (
                      <div className="social-nav">
                        <h2 className="widget-title">
                          {_.get(
                            this.props,
                            "pageContext.site.siteMetadata.footer.social_title"
                          )}
                        </h2>
                        <Social {...this.props} />
                      </div>
                    )}
                  </div>
                </nav>
              )}
              <div className="widget footer-branding">
                <h6
                  css={css`
                    color: unset;
                  `}
                >
                  Psycholog, psychoterapia, joga - Warszwa Ursus
                </h6>
                <p>
                  Nasza placówka znajduje się w Warszawie Ursus, gdzie
                  świadczymy psychoterapię dorosłych, psychoterapię dzieci i
                  młodzieży, psychoterapię indywidualną i psychoterapię par,
                  zajęcia jogi, warsztaty rozwojowe.
                </p>
              </div>
              {_.get(
                this.props,
                "pageContext.site.siteMetadata.footer.has_subscribe"
              ) && (
                <div className="widget footer-subscribe">
                  <h2 className="widget-title">
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.footer.subscribe_title"
                    )}
                  </h2>
                  {_.get(
                    this.props,
                    "pageContext.site.siteMetadata.footer.subscribe_content"
                  ) && (
                    <p>
                      {htmlToReact(
                        _.get(
                          this.props,
                          "pageContext.site.siteMetadata.footer.subscribe_content"
                        )
                      )}
                    </p>
                  )}

                  <SubscribeForm {...this.props} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="site-info outer">
          <div className="inner">
            {htmlToReact(
              _.get(this.props, "pageContext.site.siteMetadata.footer.content")
            )}
            &nbsp;
            {_.map(
              _.get(this.props, "pageContext.site.siteMetadata.footer.links"),
              (link, link_idx) => (
                <React.Fragment key={link_idx}>
                  <Link
                    key={link_idx}
                    to={_.get(link, "url")}
                    {...(_.get(link, "new_window")
                      ? { target: "_blank", rel: "noopener" }
                      : null)}
                  >
                    {_.get(link, "text")}
                  </Link>
                  .
                </React.Fragment>
              )
            )}
          </div>
        </div>
      </footer>
    );
  }
}
