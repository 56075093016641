import _ from "lodash";

export const getFeaturesByCategory = (features) => {
  // hack to set order
  const featuresByCategoryTemplate = {
    "Usługi psychologiczne": [],
    "Usługi psychiatryczne": [],
    "Usługi psychologiczno-pedagogiczne": [],
    "Usługi logopedyczne": [],
    "Integracja sensoryczna (SI)": [],
    "Warsztaty dla dzieci i młodzieży": [],
    "Warsztaty dla rodziców i opiekunów": [],
    Warsztaty: [],
    "Usługi dodatkowe": [],
  };
  const result = features.reduce((acc, post) => {
    const category = _.get(post, "frontmatter.category");

    acc[category] = acc[category] || [];
    acc[category].push(post);

    return acc;
  }, featuresByCategoryTemplate);

  return Object.fromEntries(Object.entries(result).filter(([key, value]) => value.length))
};
