import ContentBlock from "./ContentBlock";
import CtaBlock from "./CtaBlock";
import CtaButtons from "./CtaButtons";
import FaqBlock from "./FaqBlock";
import FeaturesManyBlock from "./FeaturesManyBlock";
import FeaturesBlock from "./FeaturesBlock";
import Footer from "./Footer";
import Header from "./Header";
import HeroBlock from "./HeroBlock";
import NewsBlock from "./NewsBlock";
import PostsBlock from "./PostsBlock";
import PricingBlock from "./PricingBlock";
import ReviewsBlock from "./ReviewsBlock";
import Social from "./Social";
import SubscribeForm from "./SubscribeForm";
import Layout from "./Layout";

export {
  ContentBlock,
  CtaBlock,
  CtaButtons,
  FaqBlock,
  FeaturesManyBlock,
  FeaturesBlock,
  Footer,
  Header,
  HeroBlock,
  NewsBlock,
  PostsBlock,
  PricingBlock,
  ReviewsBlock,
  Social,
  SubscribeForm,
  Layout,
};

export default {
  ContentBlock,
  CtaBlock,
  CtaButtons,
  FaqBlock,
  FeaturesManyBlock,
  FeaturesBlock,
  Footer,
  Header,
  HeroBlock,
  NewsBlock,
  PostsBlock,
  PricingBlock,
  ReviewsBlock,
  Social,
  SubscribeForm,
  Layout,
};
