import React from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import Header from "./Header";
import Footer from "./Footer";
import "../sass/main.scss";

export default class Body extends React.Component {
  render() {
    const { siteUrl: originUrl } =
      this.props?.pageContext?.site?.siteMetadata || {};
    const { url, frontmatter } = this.props?.pageContext || {};
    const siteUrl = `${originUrl}${url}`;
    const img = frontmatter?.content_img_path
      ? frontmatter?.content_img_path
      : frontmatter?.img_path;
    const contentImgPath = img ? `${originUrl}${img}` : "";

    const description =
      frontmatter?.excerpt ||
      frontmatter?.subtitle ||
      "Psycholog, Psychoterapia, Joga - Warszawa Ursus. Bardzo Dobre opinie. Kompleksowa pomoc w leczeniu depresji, nerwicy. Klub Jogi ☎ 575-550-548. ";

    const tagsString =
      "psycholog, psychoterapia, joga, Warszawa Ursus, yoga, psychoterapeuta, depresja, zaburzenia lękowe, nerwica, terapia par";
    const siteName = this.props?.pageContext?.site?.siteMetadata?.header?.title;
    return (
      <React.Fragment>
        <Helmet htmlAttributes={{ lang: "pl" }}>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta property="fb:app_id" content="575667446611871" />
          <meta name="google" content="notranslate" />
          <meta property="og:locale" content="pl_PL" />
          {siteName && <meta property="og:site_name" content={siteName} />}
          {description && <meta name="description" content={description} />}
          {description && (
            <meta property="og:description" content={description} />
          )}
          <meta name="keywords" content={tagsString} />
          <script type="application/ld+json">
            {`{
              "@context": "http://schema.org/",
              "@type": "Organization",
              "url": "${originUrl}",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Ursus, Warszawa, Polska",
                "postalCode": "02-495",
                "streetAddress": "Drzymały 18/24"
              },
              "email": "kontakt(at)dobra-przestrzen.pl",
              "name": "Dobra przestrzeń",
              "telephone": "48 575 550 548"
            }`}
          </script>
          <link
            href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i&display=swap&subset=latin-ext"
            rel="stylesheet"
          />
          <script
            defer
            src="https://identity.netlify.com/v1/netlify-identity-widget.js"
          />
          {siteUrl && <link rel="canonical" href={siteUrl} />}
          {siteUrl && <meta property="og:url" content={siteUrl} />}
          {contentImgPath && (
            <meta property="og:image" content={contentImgPath} />
          )}
          {this.props?.pageContext?.frontmatter?.title && (
            <meta
              property="og:title"
              content={this.props?.pageContext?.frontmatter?.title}
            />
          )}
        </Helmet>
        <div
          id="page"
          className={
            "site palette-" +
            _.get(this.props, "pageContext.site.siteMetadata.palette")
          }
        >
          {/*<Banner />*/}
          <Header {...this.props} />
          <main id="content" className="site-content">
            {this.props.children}
          </main>
          <Footer {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}
