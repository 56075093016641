import React from "react";
import _ from "lodash";

import { Link, safePrefix } from "../utils";

export default class Header extends React.Component {
  render() {
    return (
      <header id="masthead" className="site-header outer">
        <div className="inner">
          <div className="site-header-inside">
            <div className="site-branding">
              {_.get(
                this.props,
                "pageContext.site.siteMetadata.header.logo_img"
              ) && (
                <p className="site-logo">
                  <Link to={safePrefix("/")}>
                    <img
                      src={safePrefix(
                        _.get(
                          this.props,
                          "pageContext.site.siteMetadata.header.logo_img"
                        )
                      )}
                      alt="Logo"
                    />
                  </Link>
                </p>
              )}
              {_.get(this.props, "pageContext.frontmatter.template") ===
              "home" ? (
                <h1 className="site-title">
                  <Link to={safePrefix("/")}>
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.header.title"
                    )}
                  </Link>
                </h1>
              ) : (
                <p className="site-title">
                  <Link to={safePrefix("/")}>
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.header.title"
                    )}
                  </Link>
                </p>
              )}
            </div>
            {_.get(this.props, "pageContext.menus.main") &&
              _.get(
                this.props,
                "pageContext.site.siteMetadata.header.has_nav"
              ) && (
                <React.Fragment>
                  <nav
                    id="main-navigation"
                    className="site-navigation"
                    aria-label="Main Navigation"
                  >
                    <div className="site-nav-inside">
                      <button id="menu-close" className="menu-toggle">
                        <span className="screen-reader-text">Open Menu</span>
                        <span className="icon-close" aria-hidden="true" />
                      </button>
                      <ul className="menu">
                        {_.map(
                          _.get(this.props, "pageContext.menus.main"),
                          (item, item_idx) => (
                            <MenuLink
                              pageContext={this.props.pageContext}
                              item={item}
                              key={item_idx}
                            />
                          )
                        )}
                        <MenuLink
                          pageContext={this.props.pageContext}
                          item={{
                            title: "Poradnia pedagogiczna",
                            url: "/poradnie/niepubliczna-poradnia-psychologiczno-pedagogiczna",
                          }}
                          title={
                            "Niepubliczna poradnia psychologiczno-pedagogiczna"
                          }
                        />
                        {/*<NavLink*/}
                        {/*  pageContext={this.props.pageContext}*/}
                        {/*  item={item}*/}
                        {/*/>*/}
                        {/*<li className="menu-item menu-button">*/}
                        {/*  <Menu>*/}
                        {/*    <MenuButton>Actions</MenuButton>*/}
                        {/*    <MenuList>*/}
                        {/*      <MenuItem>Download</MenuItem>*/}
                        {/*      <MenuLink*/}
                        {/*        as="a"*/}
                        {/*        href="https://reacttraining.com/workshops/"*/}
                        {/*      >*/}
                        {/*        Attend a Workshop*/}
                        {/*      </MenuLink>*/}
                        {/*    </MenuList>*/}
                        {/*  </Menu>*/}
                        {/*</li>*/}
                        {_.get(
                          this.props,
                          "pageContext.site.siteMetadata.header.menu.actions"
                        ) &&
                          _.map(
                            _.get(
                              this.props,
                              "pageContext.site.siteMetadata.header.menu.actions"
                            ),
                            (action, action_idx) => (
                              <li
                                key={action_idx}
                                className="menu-item menu-button"
                              >
                                <Link
                                  to={safePrefix(_.get(action, "url"))}
                                  className="button"
                                >
                                  {_.get(action, "label")}
                                </Link>
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                  </nav>
                  <button id="menu-open" className="menu-toggle">
                    <span className="screen-reader-text">Close Menu</span>
                    <span className="icon-menu" aria-hidden="true" />
                  </button>
                </React.Fragment>
              )}
          </div>
        </div>
      </header>
    );
  }
}

const MenuLink = ({ pageContext, item, title }) => {
  return (
    <li
      className={
        "menu-item " +
        ((pageContext && pageContext.url) === _.get(item, "url")
          ? " current-menu-item"
          : "")
      }
      title={title}
    >
      <Link to={safePrefix(_.get(item, "url"))}>{_.get(item, "title")}</Link>
    </li>
  );
};
