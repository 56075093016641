import React from "react";
import _ from "lodash";
import { FaCheck } from "react-icons/fa";

import { getPages, htmlToReact, Link, safePrefix } from "../utils";
import { css } from "styled-components/macro";
import { between } from "polished";
import { getFeaturesByCategory } from "../utils/getFeaturesByCategory";

export default class FeaturesManyBlock extends React.Component {
  render() {
    let displayPosts = getPages(this.props.pageContext.pages, "/oferta").filter(
      (post) => post.name !== "index"
    );
    const featuresByCategory = getFeaturesByCategory(displayPosts);
    return (
      <section
        id={_.get(this.props, "section.section_id")}
        className={
          "block posts-block bg-" + _.get(this.props, "section.bg") + " outer"
        }
      >
        <div className="block-header inner-small">
          <h2 className="block-title">{_.get(this.props, "section.title")}</h2>
          {_.get(this.props, "section.subtitle") && (
            <p className="block-subtitle">
              {htmlToReact(_.get(this.props, "section.subtitle"))}
            </p>
          )}
        </div>
        <div className="inner">
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 3rem;
            `}
          >
            {Object.entries(featuresByCategory).map(
              ([category, posts], index) => (
                <div key={index}>
                  <h3
                    css={css`
                      font-size: ${between("18px", "20px")};
                      margin-bottom: 0.5rem;
                    `}
                  >
                    {category}
                  </h3>
                  <div
                    css={css`
                      border-bottom: 1px solid #fe8a00;
                      max-width: 50px;
                      margin-bottom: 1.5rem;
                    `}
                  />
                  <div
                    css={css`
                      display: grid;
                      grid-template-columns: repeat(
                        auto-fill,
                        minmax(240px, 1fr)
                      );
                      gap: 1rem;
                    `}
                  >
                    {_.map(posts, (post, post_idx) => (
                      <article
                        key={post_idx}
                        css={css`
                          display: flex;
                          gap: 0.5rem;
                          font-size: ${between("16px", "17px")};
                        `}
                      >
                        <FaCheck
                          css={css`
                            color: #fe8a00;
                            flex-shrink: 0;
                          `}
                        />
                        <div>
                          <header
                            css={css`
                              margin-bottom: 0;
                            `}
                          >
                            <h3
                              css={css`
                                font-size: ${between("16px", "17px")};
                                margin-bottom: 0.5rem;
                              `}
                            >
                              <Link
                                to={safePrefix(_.get(post, "url"))}
                                rel="bookmark"
                                css={css`
                                  border: none;
                                `}
                              >
                                {_.get(post, "frontmatter.title")}
                              </Link>
                            </h3>
                          </header>
                          {/*<div>*/}
                          {/*  <p*/}
                          {/*    css={css`*/}
                          {/*      font-size: ${between("14px", "16px")};*/}
                          {/*    `}*/}
                          {/*  >*/}
                          {/*    {_.get(post, "frontmatter.excerpt")}*/}
                          {/*  </p>*/}
                          {/*</div>*/}
                        </div>
                      </article>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </section>
    );
  }
}
